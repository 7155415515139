import queryString from 'query-string'
import { apiResponseHandler, getHeaders } from '../../api-utils'

export const ENDPOINT_BASE_URL = '/api/admin/deposits'

export const getFilteredPackageList = async ({
  accessTokenPromise,
  nameOrSku,
}) => {
  const endpoint = `/api/admin/packages?name_or_sku=${nameOrSku}`
  const accessToken = await accessTokenPromise
  return fetch(
    endpoint,
    { headers: getHeaders(accessToken) },
  )
    .then(apiResponseHandler)
}

export const getCountries = async ({ accessTokenPromise }) => (
  fetch('/api/admin/countries', {
    headers: getHeaders(await accessTokenPromise),
  })
).then(apiResponseHandler)

export const getCurrencies = async ({ accessTokenPromise }) => (
  fetch('/api/admin/currencies', {
    headers: getHeaders(await accessTokenPromise),
  })
).then(apiResponseHandler)

export const getDeposits = async ({
  accessTokenPromise,
  brandFilter,
  barcodeFilter,
  countryFilter,
  currencyFilter,
  itemsPerPage,
  packageIdFilter,
  packageNameFilter,
  page,
  retailerIdFilter,
  supplierFilter,
}) => {
  const barcodesArray = barcodeFilter?.split(/[, ]+/)

  const queryParams = queryString.stringify(
    {
      barcode_values: barcodesArray,
      brand_id: brandFilter,
      country: countryFilter,
      currency: currencyFilter,
      items_per_page: itemsPerPage,
      name: packageNameFilter,
      package_id: packageIdFilter,
      page,
      retailer_id: retailerIdFilter,
      supplier_id: supplierFilter,
    },
    {
      arrayFormat: 'bracket',
    },
  )

  const endpoint = `${ENDPOINT_BASE_URL}${queryParams ? `?${queryParams}` : ''}`
  const accessToken = await accessTokenPromise

  return fetch(
    endpoint,
    { headers: getHeaders(accessToken) },
  )
    .then(res => res.json())
}

export const updateDeposit = async ({ accessTokenPromise, fields }) => (
  fetch(`${ENDPOINT_BASE_URL}/${fields.id}`, {
    body: JSON.stringify({
      ...fields,
    }),
    headers: getHeaders(await accessTokenPromise),
    method: 'PUT',
  })
).then(apiResponseHandler)

export const createDeposit = async ({ accessTokenPromise, fields }) => (
  fetch(ENDPOINT_BASE_URL, {
    body: JSON.stringify({
      ...fields,
    }),
    headers: getHeaders(await accessTokenPromise),
    method: 'POST',
  })
).then(apiResponseHandler)

export const deleteDeposit = async ({ accessTokenPromise, recordId }) => (
  fetch(`${ENDPOINT_BASE_URL}/${recordId}`, {
    headers: getHeaders(await accessTokenPromise),
    method: 'DELETE',
  })
).then(apiResponseHandler)
